<template>
  <!--begin::Warranty Product Listing-->
  <div class="job-template" v-if="getPermission('warranty:view')">
    <PageHeaderCount
      moduleType="warranty"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="warranty_status_count"
      countkey="warranty_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'warranty-listing'"
      v-if="getPermission('warranty:view')"
    >
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-container class="pt-0 m-0 px-0">
            <v-layout>
              <v-flex>
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template>{{ item.warranty_status_count }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field
                        v-model="filter.search"
                        v-on:click:clear="clearFilter('search')"
                        clearable
                        dense
                        filled
                        flat
                        label="Search"
                        hide-details
                        solo
                        :disabled="dataLoading"
                        :loading="dataLoading"
                        color="cyan"
                        @keydown.enter="getWarranty"
                        @keydown.tab="getWarranty"
                      ></v-text-field>
                    </div>
                  </template>
                  <span>Search</span>
                </v-tooltip>
              </v-flex>
              <v-flex v-if="false">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-autocomplete
                        v-model="filter.customer"
                        hide-details
                        :items="customerList"
                        clearable
                        dense
                        flat
                        filled
                        label="Customer"
                        item-color="cyan"
                        color="cyan"
                        solo
                        :disabled="dataLoading"
                        :loading="dataLoading"
                        :item-text="'customer.display_name'"
                        :item-value="'customer.customer'"
                        v-on:change="getWarranty"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Customer(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <template v-if="item.customer">
                            <v-list-item-avatar>
                              <v-img
                                :lazy-src="$defaultProfileImage"
                                :src="$assetAPIURL(item.customer.profile_logo)"
                                aspect-ratio="1"
                                class="margin-auto grey lighten-5 custom-grey-border"
                                transition="fade-transition"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-capitalize font-weight-500 font-size-16"
                                >{{
                                  item.customer.display_name
                                }}</v-list-item-title
                              >
                              <v-list-item-title
                                class="text-capitalize font-weight-500 font-size-16"
                                >{{
                                  item.customer.company_name
                                }}</v-list-item-title
                              >
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="item.contact_person"
                              class="align-self-center"
                            >
                              <v-list-item-subtitle
                                class="text-lowercase pb-2 font-weight-500 font-size-14"
                                >{{
                                  item.contact_person.primary_email
                                }}</v-list-item-subtitle
                              >
                              <v-list-item-subtitle
                                class="text-lowercase font-weight-500 font-size-14"
                                >{{
                                  item.contact_person.primary_phone
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-action>
                          </template>
                        </template>
                      </v-autocomplete>
                    </div>
                  </template>
                  <span>Select Customer</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-autocomplete
                        v-model="filter.job"
                        hide-details
                        :items="jobList"
                        clearable
                        dense
                        flat
                        filled
                        label="Job"
                        item-color="cyan"
                        color="cyan"
                        solo
                        :disabled="dataLoading"
                        :loading="dataLoading"
                        item-text="barcode"
                        item-value="id"
                        v-on:change="getWarranty"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Job(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16 max-content-width"
                              >Job# {{ item.barcode }}</v-list-item-title
                            >
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16 max-content-width"
                              >{{ item.title }}</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-if="item.property"
                              class="pb-2 pl-3 font-weight-500 font-size-14 max-content-width"
                            >
                              <template v-if="item.property.unit_no"
                                >{{ item.property.unit_no }},
                              </template>
                              <template v-if="item.property.street_1"
                                >{{ item.property.street_1 }},
                              </template>
                              <template v-if="item.property.street_2"
                                >{{ item.property.street_2 }},
                              </template>
                              <template v-if="item.property.zip_code"
                                >{{ item.property.zip_code }}
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </div>
                  </template>
                  <span>Select Job</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-autocomplete
                        v-model="filter.product"
                        hide-details
                        :items="filteredProductList"
                        :search-input.sync="searchProduct"
                        clearable
                        dense
                        flat
                        filled
                        label="Product / Equipment"
                        item-color="cyan"
                        color="cyan"
                        solo
                        no-filter
                        :disabled="dataLoading"
                        :loading="dataLoading"
                        item-text="barcode"
                        item-value="id"
                        v-on:change="getWarranty"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Product(s) / Equipment(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16 max-content-width"
                            >
                              {{ item.barcode }}</v-list-item-title
                            >
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16 max-content-width"
                              >{{ item.name }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </div>
                  </template>
                  <span>Select Product / Equipment</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            filled
                            flat
                            label="Warranty Dates Range"
                            hide-details
                            solo
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            v-on:click:clear="clearFilter('dates')"
                            clearable
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :value="formattedDate"
                            color="cyan"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="cyan"
                          range
                          :disabled="dataLoading"
                          :loading="dataLoading"
                          v-on:change="getWarranty"
                          v-model="dates"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                  <span>Warranty Dates Range</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <div class="mr-1"></div>
              </v-flex>
              <v-flex>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="width-100 height-100 custom-bold-button white--text"
                      color="cyan"
                      tile
                      depressed
                      :disabled="dataLoading"
                      :loading="dataLoading"
                      v-on:click="getWarranty"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>Search</span>
                </v-tooltip>
              </v-flex>
              <v-flex>
                <div class="mr-1"></div>
              </v-flex>
              <v-flex>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="width-100 height-100 custom-bold-button white--text"
                      color="cyan"
                      tile
                      depressed
                      :disabled="dataLoading"
                      :loading="dataLoading"
                      v-on:click="addWarrantyDialog = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Warranty</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-container>
          <ListingTable
            :columnCount="5"
            :dataLoading.sync="dataLoading"
            :rowData.sync="warranty"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <th class="simple-table-th" width="200">Customer</th>
                  <th class="simple-table-th" width="300">Job</th>
                  <th class="simple-table-th" width="300">Description</th>
                  <th class="simple-table-th" width="200">
                    Product / Equipment
                  </th>
                  <th class="simple-table-th" width="200">Serial No.</th>
                  <th class="simple-table-th" width="200">Warranty Dates</th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom custom-border-top">
                <template v-if="warranty.length > 0">
                  <tr
                    link
                    :key="index"
                    v-for="(row, index) in warranty"
                    v-on:click="detailDialogConfirm(row)"
                    :class="{ 'warranty-expired': row.is_expired }"
                  >
                    <td class="simple-table-td">
                      <div class="warranty-listing-product">
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Name: </b>
                          {{ row.customer_display_name }}
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Company: </b> {{ row.customer_company_name }}
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Email: </b>
                          {{ row.customer_primary_email }}
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Phone: </b>
                          {{ row.customer_primary_phone }}
                        </p>
                      </div>
                    </td>
                    <td class="simple-table-td">
                      <div
                        class="warranty-listing-product"
                        v-if="row.ticket_id"
                      >
                        <div v-on:click.stop class="max-content-width">
                          <Barcode
                            route="job.detail"
                            :barcode="row.ticket_barcode"
                            :id="row.ticket_id"
                          ></Barcode>
                        </div>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Title: </b>{{ row.ticket_title }}
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis-two-line">
                          <b>Property: </b> {{ row.ticket_property }}
                        </p>
                      </div>
                      <div v-else>
                        <em>No Job</em>
                      </div>
                    </td>
                    <td class="simple-table-td">
                      <p class="m-0">
                        {{ row.warranty_description }}
                      </p>
                    </td>
                    <td class="simple-table-td" width="100">
                      <v-chip
                        small
                        label
                        class="custom-status font-weight-600 custom-grey-border text-uppercase"
                        text-color="white"
                        :color="
                          row.product_type == 'goods'
                            ? 'cyan darken-1'
                            : 'orange darken-1'
                        "
                      >
                        {{ getProductType(row.product_type) }}
                      </v-chip>
                      <p
                        class="m-0 custom-nowrap-ellipsis"
                        v-on:click.stop="routeToDetail(row)"
                      >
                        <Barcode :barcode="row.product_barcode"></Barcode>
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        {{ row.product_name }}
                      </p>
                    </td>
                    <td class="simple-table-td" width="100">
                      <p class="m-0 custom-nowrap-ellipsis">
                        {{ row.warranty_unique_id }}
                      </p>
                    </td>
                    <td class="simple-table-td" width="100">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Start: </b>{{ formatDate(row.warranty_start_date) }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>End: </b>{{ formatDate(row.warranty_end_date) }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <template
                          v-if="
                            momentObject().isAfter(
                              momentObject(row.warranty_end_date)
                            )
                          "
                        >
                          <b>Expired</b>
                        </template>
                        <template v-else>
                          <b
                            >Expiring on
                            {{ formatDate(row.warranty_end_date) }} midnight.</b
                          >
                        </template>
                      </p>
                    </td>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no warranty at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
          <ListingFooter
            :dataLoading.sync="dataLoading"
            :showingFrom.sync="showingFrom"
            :showingTo.sync="showingTo"
            :totalRows.sync="totalRows"
            :currentPage.sync="currentPage"
            :totalPages.sync="totalPages"
          ></ListingFooter>
          <template v-if="getPermission('warranty:view')">
            <Dialog
              :commonDialog="detailDialog"
              :dialogWidth.sync="dialogWidth"
            >
              <template v-slot:title> Warranty Detail </template>
              <template v-slot:body>
                <WarrantyDetail :detail.sync="warrantyDetail"></WarrantyDetail>
              </template>
              <template v-slot:action>
                <template v-if="getPermission('warranty:update')">
                  <v-btn
                    class="mx-2 custom-grey-border custom-bold-button text-white"
                    color="cyan"
                    v-on:click="updateWarrantyDialog = true"
                  >
                    Update
                  </v-btn>
                </template>
                <template v-if="getPermission('warranty:delete')">
                  <v-btn
                    class="mx-2 custom-grey-border custom-bold-button text-white"
                    color="red darken-1"
                    v-on:click="voidWarrantyDialog = true"
                  >
                    Void
                  </v-btn>
                </template>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="detailDialog = false"
                >
                  Close
                </v-btn>
              </template>
            </Dialog>
          </template>
          <template
            v-if="updateWarrantyDialog && getPermission('warranty:update')"
          >
            <ManageWarranty
              :warrantyDetail.sync="warrantyDetail"
              :dialogStatus.sync="updateWarrantyDialog"
              v-on:close:dialog="updateWarrantyDialog = false"
              v-on:update:warranty="getWarranty"
            ></ManageWarranty>
          </template>
          <template
            v-if="addWarrantyDialog && getPermission('warranty:create')"
          >
            <ManageWarranty
              :allCustomerList.sync="allCustomerList"
              :allProductList.sync="allProductList"
              :dialogStatus.sync="addWarrantyDialog"
              v-on:close:dialog="addWarrantyDialog = false"
              v-on:update:warranty="getWarranty"
            ></ManageWarranty>
          </template>
          <template v-if="getPermission('warranty:delete')">
            <Dialog
              :commonDialog="voidWarrantyDialog"
              :dialogWidth.sync="dialogWidth"
            >
              <template v-slot:title> Confirmation </template>
              <template v-slot:body>
                <p class="font-weight-500 font-size-18 color-custom-blue">
                  Are you sure, you want to void warranty ?
                </p>
                <p class="text--secondary">
                  Note: Warranty will be removed from {{ productType }}#
                  {{ productBarcode }} - {{ productName }} of Job#
                  {{
                    warrantyDetail.ticket ? warrantyDetail.ticket.barcode : ""
                  }}
                  &amp; cannot be retrieved later.
                </p>
              </template>
              <template v-slot:action>
                <v-btn
                  :disabled="warrantyLoading"
                  :loading="warrantyLoading"
                  class="mx-2 custom-grey-border custom-bold-button text-white"
                  color="red darken-1"
                  v-on:click="voidWarranty"
                >
                  Yes
                </v-btn>
                <v-btn
                  :disabled="warrantyLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  v-on:click="voidWarrantyDialog = false"
                >
                  No
                </v-btn>
              </template>
            </Dialog>
          </template>
        </v-container>
      </template>
    </ListingTemplate>
  </div>

  <!--end::Warranty Product Listing-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment-timezone";
import { QUERY, DELETE, GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import WarrantyDetail from "@/view/pages/warranty/Warranty-Detail.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ManageWarranty from "@/view/pages/partials/Create-Or-Update-Warranty.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "warranty-product-list",
  data() {
    return {
      dates: new Array(),
      allCustomerList: new Array(),
      allProductList: new Array(),
      filteredProducts: new Array(),
      statusList: new Array(),
      warranty: new Array(),
      queryParams: new Object(),
      warrantyLoading: false,
      dataLoading: true,
      detailDialog: false,
      productType: null,
      searchProduct: null,
      productBarcode: null,
      productName: null,
      status: "all",
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      updateWarrantyDialog: false,
      addWarrantyDialog: false,
      voidWarrantyDialog: false,
      warrantyUpdate: new Object(),
      warrantyDetail: new Object(),
      momentObject: moment,
      customerList: new Array(),
      jobList: new Array(),
      productList: new Array(),
      filter: {
        search: null,
        customer: null,
        product: null,
        job: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  components: {
    Barcode,
    Dialog,
    ListingTable,
    ListingFooter,
    ManageWarranty,
    WarrantyDetail,
    PageHeaderCount,
    ListingTemplate,
  },
  methods: {
    updateSearch(param) {
      let searchResult = this.lodash.filter(
        this.allProductList,
        function (row) {
          let result = false;
          if (!result && row.barcode && param) {
            result = row.barcode.toLowerCase().search(param.toLowerCase()) >= 0;
          }
          if (!result && row.name && param) {
            result = row.name.toLowerCase().search(param.toLowerCase()) >= 0;
          }
          if (!result && row.part_number && param) {
            result =
              row.part_number.toLowerCase().search(param.toLowerCase()) >= 0;
          }
          if (!result && row.serial_number && param) {
            result =
              row.serial_number.toLowerCase().search(param.toLowerCase()) >= 0;
          }
          return result;
        }
      );
      this.filteredProducts = this.lodash.isArray(searchResult)
        ? searchResult
        : [];
    },
    routeToDetail(row) {
      if (row) {
        if (row.product_type === "goods") {
          this.$router.push(
            this.getDefaultRoute("product.detail", {
              params: {
                id: row.product_id,
              },
            })
          );
        } else if (row.product_type === "equipment") {
          this.$router.push(
            this.getDefaultRoute("equipment.detail", {
              params: {
                id: row.product_id,
              },
            })
          );
        }
      }
    },
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
        case "equipment":
          result = "Equipment";
          break;
      }
      return result;
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = new Array();
          break;
      }
      _this.getWarranty();
    },
    updateWarranty() {
      const _this = this;
      _this.warrantyLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "warranty/" + _this.warrantyDetail.id,
        })
        .then(() => {
          _this.voidWarrantyDialog = false;
          _this.detailDialog = false;
          _this.warrantyDetail = new Object();
          _this.productType = null;
          _this.productBarcode = null;
          _this.productName = null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.warrantyLoading = false;
          _this.getWarranty();
        });
    },
    voidWarranty() {
      const _this = this;
      _this.warrantyLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "warranty/" + _this.warrantyDetail.id,
        })
        .then(() => {
          _this.voidWarrantyDialog = false;
          _this.detailDialog = false;
          _this.warrantyDetail = new Object();
          _this.productType = null;
          _this.productBarcode = null;
          _this.productName = null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.warrantyLoading = false;
          _this.getWarranty();
        });
    },
    detailDialogConfirm(row) {
      this.warrantyDetail = row;
      this.productType =
        row.product_type == "equipment" ? "Equipment" : "Product";
      this.productBarcode = row.product_barcode;
      this.productName = row.product_name;
      this.detailDialog = true;
    },
    getJobs() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "job/list-all" })
        .then(({ data }) => {
          _this.jobList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getWarrantyProducts() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "product/warranty" })
        .then(({ data }) => {
          _this.productList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getWarranty() {
      const _this = this;

      let filter = {
        search: _this.filter.search || undefined,
        customer:
          _this.lodash.toSafeInteger(_this.filter.customer) || undefined,
        product: _this.lodash.toSafeInteger(_this.filter.product) || undefined,
        job: _this.lodash.toSafeInteger(_this.filter.job) || undefined,
        daterange: _this.dates || undefined,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.daterange = undefined;
      }

      let per_page = _this.paginationPageSize;
      let current_page = _this.currentPage;
      let status = _this.status;

      clearTimeout(_this.timeout);
      _this.warranty = new Array();
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "warranty",
            data: { filter, per_page, current_page, status },
          })
          .then(({ data }) => {
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.warranty = data.rows;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.datePicker = false;
            _this.warrantyLoading = false;
            _this.dataLoading = false;
            _this.detailDialog = false;
            _this.productType = null;
            _this.productBarcode = null;
            _this.productName = null;
            _this.updateWarrantyDialog = false;
            _this.addWarrantyDialog = false;
            _this.voidWarrantyDialog = false;
          });
      }, _this.timeoutLimit);
    },
    getAllCustomers() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "customer-list" })
        .then(({ data }) => {
          _this.allCustomerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getAllProducts() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "product/get-line-products" })
        .then(({ data }) => {
          _this.allProductList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    pushQueryParams() {
      const _this = this;
      if (
        !_this.lodash.isEqual(
          _this.$route.query,
          _this.lodash.omitBy(_this.queryParams, _this.lodash.isNil)
        )
      ) {
        _this.$router
          .replace(
            _this.getDefaultRoute("warranty", { query: _this.queryParams })
          )
          .then(() => {
            _this.getWarranty();
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.getWarranty();
      }
    },
  },
  watch: {
    searchProduct(new_param, old_param) {
      if (new_param != old_param) {
        this.updateSearch(new_param);
      }
    },
    status(params) {
      this.currentPage = 1;
      if (params) {
        this.lodash.assign(this.queryParams, {
          status: params,
        });
        this.pushQueryParams();
      }
    },
  },
  mounted() {
    const _this = this;
    _this.getWarranty();
    _this.getJobs();
    _this.getAllCustomers();
    _this.getAllProducts();
    _this.getWarrantyProducts();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Warranty",
      },
    ]);

    _this.status = _this.$route.query.status || _this.status;

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getWarranty();
    });
  },
  computed: {
    filteredProductList() {
      return this.filteredProducts.length > 0
        ? this.filteredProducts
        : this.searchProduct
        ? []
        : this.allProductList;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.warranty) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.warranty ? this.warranty.length : 0);
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
