<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          <template v-if="lodash.isEmpty(warrantyDetail) === false">
            Update Warranty
          </template>
          <template v-else> Add Warranty </template>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 60vh; position: relative"
          > -->
          <v-form
            ref="warrantyForm"
            v-model="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateWarranty"
          >
            <v-container fluid>
              <v-row>
                <template v-if="lodash.isEmpty(warrantyDetail)">
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600">Customer</label>
                    <v-autocomplete
                      v-model="warrantyOption.customer"
                      :items="allCustomerList"
                      clearable
                      dense
                      flat
                      filled
                      label="Display Name"
                      item-color="cyan"
                      color="cyan"
                      solo
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      item-text="company_name"
                      item-value="id"
                      :rules="[
                        validateRules.required(
                          warrantyOption.customer,
                          'Customer'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Customer(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <template>
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(item.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto grey lighten-5 custom-grey-border"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{ item.display_name }}</v-list-item-title
                            >
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{ item.company_name }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600">Product / Equipment</label>
                    <v-autocomplete
                      v-model="warrantyOption.product"
                      :items="allProductList"
                      clearable
                      dense
                      flat
                      filled
                      label="Product / Equipment"
                      item-color="cyan"
                      color="cyan"
                      solo
                      v-on:change="updateSerialNumber($event)"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      item-text="label"
                      item-value="id"
                      :rules="[
                        validateRules.required(
                          warrantyOption.product,
                          'Product / Equipment'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Product(s) / Equipment(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16 max-content-width"
                            ><template v-if="item.product_type === 'goods'"
                              >Product</template
                            ><template
                              v-else-if="item.product_type === 'equipment'"
                              >Equipment</template
                            ># {{ item.barcode }}</v-list-item-title
                          >
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16 max-content-width"
                            >{{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </template>
                <v-col md="6" class="py-0">
                  <label class="font-weight-600">Start Date</label>
                  <DatePicker
                    solo
                    :defaultDate.sync="warrantDefaultStartDate"
                    :pageLoading.sync="pageLoading"
                    :placeholder="'Start Date'"
                    v-model="warrantyOption.start_date"
                  ></DatePicker>
                </v-col>
                <v-col md="6" class="py-0">
                  <label class="font-weight-600">Serial No.</label>
                  <v-text-field
                    dense
                    filled
                    :rules="[
                      validateRules.required(
                        warrantyOption.unique_id,
                        'Serial No.'
                      ),
                    ]"
                    color="cyan"
                    label="Serial No."
                    solo
                    flat
                    v-model="warrantyOption.unique_id"
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="py-0">
                  <label class="font-weight-600">Description</label>
                  <v-textarea
                    v-model="warrantyOption.description"
                    auto-grow
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                    label="Description"
                    row-height="20"
                  ></v-textarea>
                </v-col>
              </v-row>
              <table class="width-100">
                <tr>
                  <td colspan="3">
                    <label class="font-weight-600">Duration</label>
                  </td>
                </tr>
                <tr
                  v-for="(warranty, index) in defaultItemWarranty"
                  :key="index"
                >
                  <td>
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      label="Duration"
                      :rules="[
                        validateRules.required(warranty.value, 'Duration'),
                      ]"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      v-model="warranty.value"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      solo
                      flat
                      :rules="[
                        validateRules.required(warranty.field, 'Duration Type'),
                      ]"
                      class="pl-2 pr-2 width-100"
                      v-model="warranty.field"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                    ></v-select>
                  </td>
                  <td style="vertical-align: top">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="red lighten-1"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </table>
            </v-container>
          </v-form>
          <!-- </perfect-scrollbar> -->
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              v-on:click="updateWarranty"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              >Save
            </v-btn>
            <v-btn
              :loading="pageLoading"
              :disabled="pageLoading"
              v-on:click="$emit('close:dialog')"
              class="mx-2 custom-grey-border custom-bold-button"
              >Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { POST, PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "create-or-update-warranty",
  mixins: [ValidationMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    allCustomerList: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    allProductList: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    warrantyDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  components: {
    DatePicker,
  },
  watch: {
    warrantyDetail: {
      deep: true,
      immediate: true,
      handler() {
        if (this.lodash.isEmpty(this.warrantyDetail) === false) {
          this.warrantyOption.id = this.warrantyDetail.id;
          this.warrantyOption.product = this.warrantyDetail.product_id;
          this.warrantyOption.customer = this.warrantyDetail.customer_id;
          this.warrantyOption.unique_id =
            this.warrantyDetail.warranty_unique_id;
          this.warrantyOption.description =
            this.warrantyDetail.warranty_description;
          this.warrantyOption.start_date =
            this.warrantyDetail.warranty_start_date;
          if (
            this.lodash.isEmpty(this.warrantyDetail.warranty_data) === false
          ) {
            this.defaultItemWarranty = this.warrantyDetail.warranty_data.map(
              function (row) {
                return {
                  warranty: row.warranty,
                  value: row.value,
                  field: row.field,
                  id: row.id,
                };
              }
            );
          }
        } else {
          if (this.defaultItemWarranty.length <= 0) {
            this.pushWarranty();
          }
        }
      },
    },
    dialogStatus(param) {
      if (!param) {
        this.warrantyOption = new Object({
          id: null,
          customer: null,
          product: null,
          start_date: null,
          unique_id: null,
          description: null,
          warranty_data: new Array(),
        });
        this.defaultItemWarranty = new Array();
      } else {
        if (this.defaultItemWarranty.length <= 0) {
          this.pushWarranty();
        }
      }
    },
  },
  data() {
    return {
      formValid: true,
      pageLoading: false,
      startDatePicker: null,
      defaultItemWarranty: new Array(),
      warrantyOption: new Object({
        id: null,
        customer: null,
        product: null,
        start_date: null,
        unique_id: null,
        description: null,
        warranty_data: new Array(),
      }),
      warrantyList: [
        { text: "30 Days", value: "30", type: "day" },
        { text: "6 Months", value: "182", type: "day" },
        { text: "1 Year", value: "365", type: "day" },
        { text: "2 Years", value: "730", type: "day" },
        { text: "3 Years", value: "1095", type: "day" },
      ],
      warrantyDurationTypes: [
        { text: "Day(s)", value: "day" },
        { text: "Week(s)", value: "week" },
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
    };
  },
  methods: {
    updateSerialNumber(param) {
      let equipment = this.lodash.find(this.allProductList, {
        id: param,
        product_type: "equipment",
      });
      console.log({ equipment });
      if (equipment) {
        this.warrantyOption.unique_id = equipment.serial_number;
        this.warrantyOption.description = equipment.description;
        let reference = equipment.reference; // customer name
        if (reference) {
          let customer = this.lodash.find(this.allCustomerList, {
            company_name: reference,
          });
          if (customer) {
            this.warrantyOption.customer = customer.id;
          }
        }
        this.warrantyOption.start_date = equipment.manufacturing_date;
        if (equipment.warranties) {
          this.defaultItemWarranty = new Array();
          for (let i = 0; i < equipment.warranties.length; i++) {
            this.defaultItemWarranty.push({
              warranty: 1,
              value: equipment.warranties[i].day,
              field: equipment.warranties[i].type,
              id: null,
            });
          }
        }
      } else {
        if (!param) {
          this.warrantyOption.unique_id = null;
        }
      }
    },
    pushWarranty() {
      if (this.defaultItemWarranty.length < this.warrantyDurationTypes.length) {
        this.defaultItemWarranty.push({
          warranty: 1,
          value: null,
          field: null,
          id: null,
        });
      }
    },
    removeWarranty(index) {
      this.defaultItemWarranty.splice(index, 1);
    },
    updateWarranty() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      let formData = new Object({
        id: _this.lodash.toSafeInteger(_this.warrantyOption.id) || null,
        customer:
          _this.lodash.toSafeInteger(_this.warrantyOption.customer) || null,
        product:
          _this.lodash.toSafeInteger(_this.warrantyOption.product) || null,
        start_date: _this.warrantyOption.start_date,
        unique_id: _this.warrantyOption.unique_id,
        description: _this.warrantyOption.description,
        warranty_data: _this.defaultItemWarranty,
      });

      let requestType = POST;
      if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store
        .dispatch(requestType, { url: "warranty", data: formData })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    warrantDefaultStartDate() {
      return this.lodash.isEmpty(this.warrantyDetail) === false
        ? this.warrantyDetail.warranty_start_date
        : new Date().toISOString().substr(0, 10);
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
